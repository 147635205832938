import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import { getWalletsReports, updateWalletsReports } from '../../../actions/report';
import { getSellerLists } from '../../../actions/seller';
import { convertCurrency, getUserId } from '../../../utils/helpers';

class Wallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: 10,
      },
      report: [],
      general: {},
      total_items: 0,
    };
  }
  filterTransaction = (seller_id) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          seller_id,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  updateWallet = async () => {
    await this.props.updateWalletsReports();
    this.paginationHandler({ selected: 0 });
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getWalletsReports({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);
  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');

    if (element && !window.choices) {
      window.choices = new window.Choices(element, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Seller',
        searchPlaceholderValue: 'Search Seller...',
        choices: [],
      });
      window.choices.clearChoices();
      element.addEventListener(
        'search',
        function (event) {
          that.onInput(event.detail.value);
        },
        false,
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.report !== nextProps.report) {
      this.setState({
        report: nextProps.report.data?.sellers || [],
        total_items: nextProps.report.data.total_items,
      });
    }
    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  render() {
    return (
      <div className="card">
        <div className="card-body">
          <div className="row align-items-center justify-content-between g-3 mb-6">
            <div className="col-12 col-md-auto">
              <h2 className="mb-0">Wallets</h2>
            </div>
            <div className="col-12 col-md-auto">
              <button className="btn btn-primary" onClick={this.updateWallet}>
                Update Wallets
              </button>
            </div>
          </div>
          <div className="row align-items-center justify-content-between g-3 mb-6">
            <div className="col-12 col-md-auto">
              <select
                id="seller"
                className="form-select"
                onChange={(e) => this.filterTransaction(e.target.value)}
              ></select>
            </div>
          </div>
          <div className="table-responsive scrollbar">
            <table className="reports-details-chart-table table table-sm fs-9 mb-0">
              <thead>
                <tr>
                  <th
                    className="align-middle pe- text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '15%' }}
                  >
                    Seller
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Last month Revenue
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Current Rank
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Revenue
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Balance
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Payout
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    On Going
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    On Hold
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Cancel
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Total Products
                  </th>
                  <th
                    className="align-middle text-end ps-4 text-body-tertiary fw-bold fs-10 text-uppercase text-nowrap"
                    scope="col"
                    style={{ width: '10%' }}
                  >
                    Sold Order
                  </th>
                </tr>
              </thead>
              <tbody className="list" id="report-data-body">
                {(this.state.report || []).length > 0 &&
                  this.state.report.map((el, index) => (
                    <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                      <td className="align-middle white-space-nowrap py-2">
                        <span className="fw-semibold text-body-highlight">{el.seller_info?.seller_name}</span>
                        <br />
                        <small className="">ID: {getUserId(el.seller_id)}</small>
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.last_month_revenue || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        {el.seller_info?.seller_rank}
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.total_revenue || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.balance || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.withdraw || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.on_going || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.on_hold || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(el.cancelled || 0, false, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        {el.seller_info?.total_products || 0}
                      </td>
                      <td className="align-middle text-end white-space-nowrap fw-semibold text-body-highlight ps-4 py-2">
                        {el.sold_orders}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="table-list row align-items-center justify-content-between py-2 pe-0 fs-9">
            <div className="d-flex justify-content-center mt-3">
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                activeClassName={'active'}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                pageLinkClassName={'page'}
                breakLinkClassName={'page'}
                previousClassName={'page-link'}
                nextClassName={'page-link'}
                initialPage={0}
                pageCount={Math.ceil(this.state.total_items / 10)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.paginationHandler}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Wallets.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  report: state.report.wallets,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, { getWalletsReports, updateWalletsReports, getSellerLists })(Wallets);
