import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';

import { getProductLists, deleteProduct, deleteBulkProduct, boostProduct } from '../../../actions/products';
import { getSellerLists } from '../../../actions/seller';

import { convertCurrency, getUserId, formatDateTime } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

const DEFAULT_PAGE_SIZE = 200;

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      productList: [],
      total_items: 0,
      selectedProduct: null,
      priority: 1,
      loading: false,
      sellers: [],
      product: {},
      product_ids: [],
      isLoaded: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleBulkDelete = this.handleBulkDelete.bind(this);
    this.searchProduct = this.searchProduct.bind(this);
    this.paginationHandler = this.paginationHandler.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  setProduct = (item) => {
    this.setState({
      product: item,
    });
  };
  handleBoost = async () => {
    await this.props.boostProduct(this.state.selectedProduct._id);
    this.paginationHandler({ selected: this.state.filter.page - 1 });
  };
  searchProduct = (keyword) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          s: keyword,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getProductLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        isLoaded: true,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
      });
    }
  };
  handleBulkDelete = async () => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      await this.props.deleteBulkProduct(this.state.product_ids);
      this.setState({
        ...this.state,
        product_ids: [],
      });
      window.location.reload();
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  selectBulk = (id) => {
    let items = [...this.state.product_ids];
    if (items.indexOf(id) >= 0) {
      items.splice(items.indexOf(id), 1);
    } else {
      items.push(id);
    }
    this.setState({
      product_ids: items,
    });
  };

  handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      await this.props.deleteProduct(id);
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  componentDidUpdate() {
    setTimeout(() => {
      const that = this;
      const element = document.querySelector('#seller');
      window.choices = new window.Choices(element, {
        silent: true,
        searchEnabled: true,
        searchChoices: false,
        placeholder: true,
        placeholderValue: 'Search Seller',
        searchPlaceholderValue: 'Search Seller...',
        choices: [],
      });
      if (!window.choices) {
        window.choices.clearChoices();
        element.addEventListener(
          'search',
          function (event) {
            that.onInput(event.detail.value);
          },
          false,
        );
      }
    }, 1000);
  }
  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);
  filterProductBySeller = (seller_id) => {
    // this.setState(
    //   {
    //     ...this.state,
    //     filter: {
    //       ...this.state.filter,
    //       created_by: seller_id,
    //     },
    //   },
    //   () => {
    //     this.paginationHandler({
    //       selected: 0,
    //     });
    //   },
    // );
  };
  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    page = page > 0 ? page - 1 : 0;
    const s = query.keyword;
    if (s) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            s: s,
          },
        },
        () => {
          this.paginationHandler({ selected: page });
        },
      );
    } else {
      this.paginationHandler({ selected: page });
    }

    this.props.getSellerLists({
      field_name: 'kyc_status',
      field_value: 2,
      page: 1,
      page_size: 20,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.products !== this.props.products) {
      this.setState({
        ...this.state,
        productList: nextProps.products.products.data.products,
        total_items: nextProps.products.products.data.total_items,
      });
    }
    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const arg = {
      page: page.selected + 1,
    };
    if (this.state.filter.s) {
      arg.keyword = this.state.filter.s;
    }
    if (this.state.filter.created_by) {
      arg.created_by = this.state.filter.created_by;
    }
    const params = new URLSearchParams(arg);
    const url = `/admin/games/products?${params}`;
    window.location.href = url;
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">Products</li>
          </ol>
        </nav>
        <h2 class="text-bold text-body-emphasis mb-5">Products</h2>
        <div
          id="advanceTable"
          data-list='{"valueNames":["name","slug","priority"],"page":200,"pagination":{"innerWindow":2,"left":1,"right":1}}'
        >
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto d-flex flex-row">
              <div class="search-box me-2">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchProduct(event.target.value)}
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <div>
                <select
                  id="seller"
                  class="form-select mb-3"
                  style={{ width: 100 }}
                  onChange={(e) => this.filterProductBySeller(e.target.value)}
                >
                  <option value={''}>Default</option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                {this.state.product_ids.length > 0 && (
                  <button onClick={this.handleBulkDelete} class="btn btn-danger me-4">
                    Delete selected ({this.state.product_ids.length})
                  </button>
                )}
                <button class="btn btn-link text-body me-4 px-0">
                  <span class="fa-solid fa-file-export fs-9 me-2"></span>Export
                </button>
                <a href="products/add" class="btn btn-primary">
                  <span class="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th class="white-space-nowrap fs-9 align-middle ps-0">
                      <div class="form-check mb-0 fs-8"></div>
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      CREATED DATE
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="customer"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      NAME
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="email"
                      style={{ width: '10%', minWidth: '150px' }}
                    >
                      SELLER
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '15%', minWidth: '200px' }}
                    >
                      CATEGORY
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '10%', minWidth: '150px' }}
                    >
                      GAME TYPE
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="mobile_number"
                      style={{ width: '10%', minWidth: '150px' }}
                    >
                      PRICE
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="priority"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      BOOST
                    </th>
                    <th class="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body">
                  {this.state.productList.map((method, index) => (
                    <tr class="hover-actions-trigger btn-reveal-trigger position-static" key={`product-${index}`}>
                      <td class="fs-9 align-middle ps-0 py-3">
                        <div class="form-check mb-0 fs-8">
                          <input
                            class="form-check-input"
                            onClick={() => this.selectBulk(method._id)}
                            type="checkbox"
                            data-bulk-select-row={method._id}
                          />
                        </div>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{formatDateTime(method.created_time * 1000)}</h6>
                      </td>
                      <td class="customer align-middle">
                        <a
                          class="d-flex align-items-center text-body text-hover-1000"
                          href="#!"
                          data-bs-toggle="modal"
                          data-bs-target="#scrollingLong"
                          onClick={() => this.setProduct(method)}
                        >
                          <div>
                            <h6 class="username mb-0 fw-semibold">{method.name}</h6>
                            <span class="username mb-0">{method.slug}</span>
                          </div>
                        </a>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{method.seller_info.seller_name}</h6>
                        <small>{getUserId(method.created_by)}</small>
                        <br />
                        <small>{method.seller_info.seller_rank}</small>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{method.category_detail.name}</h6>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <h6 class="username mb-0 fw-semibold">{method.product_type_detail.name}</h6>
                      </td>
                      <td class="customer align-middle white-space-nowrap">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertCurrency(method.price, true, this.props.settings?.data?.sgd),
                          }}
                        />
                      </td>
                      <td class="priority align-middle white-space-nowrap">{method.priority > 1 ? '1' : '0'}</td>

                      <td class="joined align-middle white-space-nowrap text-body-tertiary text-end">
                        <a
                          className="text-danger"
                          href="#!"
                          onClick={() => {
                            this.setState({ selectedProduct: method }, () => {
                              this.handleBoost();
                            });
                          }}
                        >
                          Boost
                        </a>
                        <a class="ms-3 text-danger" href="#!" onClick={() => this.handleDelete(method._id)}>
                          Remove
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9 mt-3">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body">
                  View {this.state.filter.page * DEFAULT_PAGE_SIZE} of {this.state.total_items}
                </p>
              </div>
              <div class="col-auto d-flex">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / DEFAULT_PAGE_SIZE)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="scrollingLong"
          tabIndex="-1"
          aria-labelledby="scrollingLongModalLabel"
          aria-hidden="true"
        >
          {this.state.product && (
            <div className="modal-dialog" style={{ maxWidth: '70%' }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="scrollingLongModalLabel">
                    {this.state.product?.name}
                  </h5>
                  <button className="btn p-1" type="button" data-bs-dismiss="modal" aria-label="Close">
                    <span className="fas fa-times fs-9"></span>
                  </button>
                </div>
                <div className="modal-body">
                  <div class="row mb-6">
                    {(this.state.product?.collection_url || []).length > 0 &&
                      (this.state.product?.collection_url || []).map((el, index) => (
                        <div class="col" key={`p-${index}`}>
                          <img src={el} style={{ width: '100px' }} />
                        </div>
                      ))}
                  </div>
                  <div className="mb-6">
                    <h4 className="mb-3">Description</h4>
                    <div dangerouslySetInnerHTML={{ __html: this.state.product?.description }}></div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-outline-primary" type="button" data-bs-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

Products.propTypes = {
  auth: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getProductLists: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  products: state.products,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getProductLists,
  deleteProduct,
  boostProduct,
  getSellerLists,
  deleteBulkProduct,
})(withRouter(Products));
