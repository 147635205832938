import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { getAffTransactionsLists } from '../../../actions/transaction';
import { getSellerLists } from '../../../actions/seller';

import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import advanceAjaxTableInit from '../../../utils/advanceAjaxTableInit';
import { getUserId, formatDateTime, convertCurrency } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

const filterKey = {
  1: 'Pending',
  2: 'Awaiting for Payment',
  11: 'Processing Payment',
  3: 'Paid',
  4: 'Completed',
  6: 'Payment Failed',
  5: 'Refund',
  10: 'Partial Refund',
  7: 'Delivered',
  8: 'Suspended',
  9: 'On Hold',
};

const statuses = [
  'UnKnown',
  'Pending',
  'Awaiting Payment',
  'Paid',
  'Completed',
  'Refund',
  'Fail',
  'Delivered',
  'Suspended',
  'On Hold',
  'Partial Refund',
  'Processing Payment',
];

const statusesColor = [
  'default',
  'warning',
  'warning',
  'success',
  'success',
  'warning',
  'danger',
  'success',
  'danger',
  'success',
  'success',
  'success',
];

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: DEFAULT_PAGE_SIZE,
      },
      transactionList: [],
      originalTransactionList: [],
      total_items: 0,
      activeStatus: '',
      sellers: [],
      date_from: '',
      date_to: '',
      csvData: [],
      loadingCsv: false,
      order: {},
    };
    this.setActiveStatus = this.setActiveStatus.bind(this);
  }

  makeAPICall = (searchValue) => {
    if (!searchValue) {
      return;
    }
    this.props.getSellerLists({
      field_name: 'kyc_status,keyword',
      field_value: `2,${searchValue}`,
      page: 1,
      page_size: 20,
    });
  };

  debounce = (fn, delay = 1000) => {
    let timerId = null;
    return (...args) => {
      clearTimeout(timerId);
      timerId = setTimeout(() => fn(...args), delay);
    };
  };

  onInput = this.debounce(this.makeAPICall, 500);

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            page: page.selected + 1,
          },
          csvData: [],
          loadingCsv: true,
        },
        () => {
          this.props.getAffTransactionsLists({
            ...this.state.filter,
            page: page.selected + 1,
          });
        },
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  generateCSV = (transactionList) => {
    const result = [
      ['id', 'status', 'created_time', 'seller_name', 'item_name', 'product_type_name', 'fee_charge_seller', 'price'],
    ];
    transactionList.forEach((el) => {
      result.push([
        el._id,
        statuses[el.status],
        moment(el.created_time * 1000).format('YYYY-MM-DD'),
        el.seller_info.seller_name,
        el.item_name,
        el.product_type_name,
        el.fee_charge_seller,
        el.price,
      ]);
    });
    this.setState({ csvData: result, loadingCsv: false });
  };
  generateTable = () => {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    const options = {
      page: 12,
      valueNames: ['seller_name', '_id', 'item_name'],
      pagination: {
        item: "<li><button class='page' type='button'></button></li>",
      },
      currentPage: parseInt(query.page) || 1,
    };

    window.tableList = advanceAjaxTableInit(options);
  };
  filterTransaction = (seller_id) => {
    this.setState(
      {
        loadingCsv: true,
      },
      () => {
        const items = [...this.state.originalTransactionList].filter((el) => el.aff_id == seller_id);
        this.setState({ transactionList: items });
        this.generateCSV(items);
      },
    );
  };
  setActiveStatus(status) {
    this.setState(
      {
        activeStatus: status,
        filter: {
          status,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
        window.choices.clearStore();
      },
    );
  }
  setOrder(order) {
    this.setState({
      order,
    });
  }
  componentDidMount() {
    this.paginationHandler({ selected: 0 });
    this.generateTable();
  }
  componentDidUpdate() {
    const that = this;
    const element = document.querySelector('#seller');
    window.choices = new window.Choices(element, {
      silent: true,
      searchEnabled: true,
      searchChoices: false,
      placeholder: true,
      placeholderValue: 'Search aff',
      searchPlaceholderValue: 'Search aff...',
      choices: [],
    });
    if (element && !window.choices) {
      window.choices.clearChoices();
      element.addEventListener(
        'search',
        function (event) {
          that.onInput(event.detail.value);
        },
        false,
      );
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.transactions !== this.props.transactions) {
      this.setState(
        {
          ...this.state,
          transactionList: nextProps.transactions.transactions.data,
          originalTransactionList: nextProps.transactions.transactions.data,
          loadingCsv: true,
        },
        () => {
          this.generateCSV(nextProps.transactions.transactions.data);
        },
      );
    }

    if (nextProps.sellers !== this.props.sellers) {
      this.setState({
        ...this.state,
        sellers: nextProps.sellers.sellers.sellers,
      });
      if (window.choices) {
        window.choices.setChoices(
          (nextProps.sellers.sellers.sellers || []).map((el) => {
            return { value: el.user_id, label: el.user_name };
          }),
          'value',
          'label',
          true,
        );
      }
    }
  }
  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Transactions</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Transactions</h2>
        <div id="advanceTable" data-list='{"page":12,"pagination":{"innerWindow":2,"left":1,"right":1}}'>
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-1">
                <form className="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <input
                    className="form-control search-input search fuzzy-search"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <span className="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                className="form-select1 ms-3"
                id="organizerSingle"
                data-choices="data-choices"
                data-options='{"removeItemButton":false,"placeholder":true}'
                onChange={(e) => this.setActiveStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {Object.keys(filterKey).map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {filterKey[value]}
                  </option>
                ))}
              </select>
              <div className="ms-3">
                <select
                  id="seller"
                  className="form-select"
                  onChange={(e) => this.filterTransaction(e.target.value)}
                ></select>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData} filename={'transactions.csv'}>
                    {this.state.loadingCsv ? 'Loading csv...' : 'Export'}
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="aff_name"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      AFFILIATE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="order"
                      style={{ width: '30%', maxWidth: '300px' }}
                    >
                      ORDER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '50px' }}
                    >
                      STATUS
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="seller_name"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      SELLER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="seller_rank"
                      style={{ width: '5%', minWidth: '80px' }}
                    >
                      SELLER RANK
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="buyer_name"
                      style={{ width: '10%', minWidth: '100px' }}
                    >
                      BUYER
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="price"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      PRICE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="fee"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      COMMISSION
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.transactionList.length > 0 ? (
                    this.state.transactionList.map(
                      (method, index) =>
                        method && (
                          <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                            <td className="aff_name align-middle white-space-nowrap">
                              <h6 className="mb-0 fw-semibold">{method.affiliate_info?.aff_name}</h6>
                              <small>{getUserId(method.aff_id)}</small>
                              <div>
                                <small>{method.affiliate_info?.aff_email}</small>
                              </div>
                            </td>
                            <td className="customer align-middle">
                              <div className="text-body text-hover-1000">
                                <div className="mb-0">
                                  <strong>Order Date</strong>: {formatDateTime(method.created_time * 1000)}
                                  <br />
                                  <strong className="">Order ID</strong>: <span className="id">{method._id}</span>
                                  <br />
                                  <strong className="">Transaction ID</strong>:{' '}
                                  <span className="merchant_trade_no">{method.merchant_trade_no}</span>
                                  <br />
                                  <strong>Order Title</strong>: <span className="item_name">{method.item_name}</span>
                                  <br />
                                  <strong>Category</strong>: <span className="item_name">{method.category_name}</span>
                                  <br />
                                </div>
                              </div>
                              <a href={`/admin/marketplace/chat/${method._id}`}>View Chat</a>
                            </td>
                            <td className="status align-middle white-space-nowrap">
                              <span
                                className={`badge badge-phoenix fs-10 badge-phoenix-${
                                  statusesColor[method.status]
                                } d-inline-flex align-items-center`}
                              >
                                {statuses[method.status]}
                              </span>
                            </td>
                            <td className="seller_name align-middle white-space-nowrap">
                              <h6 className="mb-0 fw-semibold">
                                <span className="">{method.seller_info.seller_name}</span>
                                <small> ({method.seller_info.seller_rank})</small>
                              </h6>
                              <small>{method.seller_id}</small>
                            </td>
                            <td className="seller_rank align-middle white-space-nowrap">{method.seller_rank}</td>
                            <td className="buyer_name align-middle white-space-nowrap">
                              <h6 className="mb-0 fw-semibold">{method.buyer_info.buyer_name}</h6>
                              <small>{method.buyer_id}</small>
                              <div>
                                <small>{method.buyer_info.buyer_email}</small>
                              </div>
                            </td>
                            <td className="price align-middle white-space-nowrap">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: convertCurrency(method.price, true, this.props.settings?.data?.sgd),
                                }}
                              />
                            </td>
                            <td className="total_price align-middle white-space-nowrap">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: convertCurrency(
                                    ((method.price * method.fee_charge_seller) / 100) * 0.25,
                                    true,
                                    this.props.settings?.data?.sgd,
                                  ),
                                }}
                              />
                            </td>
                          </tr>
                        ),
                    )
                  ) : (
                    <tr>
                      <td colSpan={10}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div class="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div class="col-auto d-flex">
                <p class="mb-0 d-none d-sm-block me-3 fw-semibold text-body" data-list-info="data-list-info"></p>
                <a class="fw-semibold" href="#!" data-list-view="*">
                  View all
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
                <a class="fw-semibold d-none" href="#!" data-list-view="less">
                  View Less
                  <span class="fas fa-angle-right ms-1" data-fa-transform="down-1"></span>
                </a>
              </div>
              <div class="col-auto d-flex">
                <button class="page-link" data-list-pagination="prev">
                  <span class="fas fa-chevron-left"></span>
                </button>
                <ul class="mb-0 pagination"></ul>
                <button class="page-link pe-0" data-list-pagination="next">
                  <span class="fas fa-chevron-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Transactions.propTypes = {
  auth: PropTypes.object.isRequired,
  transactions: PropTypes.object.isRequired,
  getAffTransactionsLists: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  transactions: state.transactions,
  sellers: state.sellers,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getAffTransactionsLists,
  getSellerLists,
})(withRouter(Transactions));
