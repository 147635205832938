import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { DebounceInput } from 'react-debounce-input';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { getPayoutsLists, updatePayout } from '../../../actions/transaction';
import { DEFAULT_PAGE_SIZE } from '../../../utils/constants';
import { formatDate, convertCurrency, getUserId } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

const filterKey = {
  1: 'Upcomming',
  2: 'Paid',
};

const statuses = ['UnKnown', 'Upcomming', 'Paid'];

const statusesColor = ['default', 'warning', 'success'];

class Payouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        total_page: 0,
        page_size: DEFAULT_PAGE_SIZE,
        order_id: '',
        date_from: '',
        date_to: '',
      },
      transactionList: [],
      reasonList: [],
      transactionIdList: [],
      total_items: 0,
      activeStatus: '',
      date_from: '',
      date_to: '',
      csvData: [],
      loadingCsv: false,
      isLoaded: false,
    };
    this.setActiveStatus = this.setActiveStatus.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            page: page.selected + 1,
          },
          csvData: [],
          loadingCsv: true,
          isLoaded: true,
        },
        () => {
          this.props.getPayoutsLists({
            ...this.state.filter,
            page: page.selected + 1,
          });
        },
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
      });
    }
  };

  handleApprove = async (id, index, status) => {
    const confirm = window.confirm('Are you sure approve this payout?');
    if (confirm) {
      const arg = { status };
      if (status === 3) {
        arg.reason = this.state.reasonList[index];
      }
      if (status === 2) {
        arg.transaction_id = this.state.transactionIdList[index];
      }
      await this.props.updatePayout(id, arg);
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  setActiveStatus(status) {
    this.setState(
      {
        activeStatus: status,
        filter: {
          ...this.state.filter,
          status,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.payouts !== this.props.payouts) {
      const arrayReason = nextProps.payouts.data.transactions.map((item) => item.reason);
      const arrayTransactionId = nextProps.payouts.data.transactions.map((item) => item.transactionId);
      this.setState(
        {
          ...this.state,
          transactionList: nextProps.payouts.data.transactions,
          total_items: nextProps.payouts.data.total_items,
          total_page: Math.ceil(nextProps.payouts.data.total_items / DEFAULT_PAGE_SIZE),
          loadingCsv: true,
          reasonList: arrayReason,
          transactionIdList: arrayTransactionId,
        },
        () => {
          this.generateCSV();
        },
      );
    }
  }
  searchItem = (order_id) => {
    this.setState(
      {
        ...this.state,
        filter: {
          ...this.state.filter,
          order_id,
          page: 1,
          page_size: DEFAULT_PAGE_SIZE,
        },
      },
      () => {
        this.paginationHandler({ selected: 0 });
      },
    );
  };
  generateCSV = () => {
    const result = [['id', 'status', 'created_time', 'seller', 'fee', 'amount']];
    this.state.transactionList.forEach((el) => {
      const subtotal = el.payout_method
        ? (el.amount * el.payout_method.fee || 0) / 100 + el.payout_method.additional_fee || 0
        : 0;
      const total = el.amount - subtotal;
      result.push([
        el._id,
        statuses[el.status],
        moment(el.created_time * 1000).format('YYYY-MM-DD'),
        el.seller_info.seller_name,
        subtotal,
        total,
      ]);
    });
    this.setState({ csvData: result, loadingCsv: false });
  };

  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    page = page > 0 ? page - 1 : 0;
    const order_id = query.order_id;
    const date_from = query.date_from;
    const date_to = query.date_to;
    const status = query.status;
    const newState = {
      ...this.state,
    };
    if (order_id) {
      newState.filter.order_id = order_id;
    }
    if (date_from) {
      newState.filter.date_from = date_from;
    }
    if (date_to) {
      newState.filter.date_to = date_to;
    }
    if (status) {
      newState.activeStatus = status;
      newState.filter.status = status;
    }

    this.setState(
      {
        ...this.state,
        ...newState,
      },
      () => {
        this.paginationHandler({ selected: page });
      },
    );
  }
  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const arg = {
      page: page.selected + 1,
    };
    if (this.state.filter.date_from) {
      arg.date_from = this.state.filter.date_from;
    }
    if (this.state.filter.date_to) {
      arg.date_to = this.state.filter.date_to;
    }
    if (this.state.filter.order_id) {
      arg.order_id = this.state.filter.order_id;
    }
    if (this.state.filter.status) {
      arg.status = this.state.filter.status;
    }
    const pathname = window.location.pathname;
    const params = new URLSearchParams(arg);
    const url = `${pathname}?${params}`;
    window.location.href = url;
  }
  render() {
    return (
      <>
        <nav class="mb-2" aria-label="breadcrumb">
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li class="breadcrumb-item active">Payouts</li>
          </ol>
        </nav>
        <h2 class="text-bold text-body-emphasis mb-5">Payouts</h2>
        <div id="members" className="table-list">
          <div class="row align-items-center justify-content-between g-3 mb-4">
            <div class="col col-auto d-flex flex-row">
              <div class="search-box me-1">
                <form class="position-relative" data-bs-toggle="search" data-bs-display="static">
                  <DebounceInput
                    placeholder="Search"
                    aria-label="Search"
                    minLength={4}
                    debounceTimeout={300}
                    className="form-control search-input search"
                    onChange={(event) => this.searchItem(event.target.value)}
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
              <select
                class="form-select1"
                id="organizerSingle"
                data-choices="data-choices"
                data-options='{"removeItemButton":false,"placeholder":true}'
                onChange={(e) => this.setActiveStatus(e.target.value)}
              >
                <option selected={this.state.activeStatus == ''} value="">
                  All
                </option>
                {Object.keys(filterKey).map((value) => (
                  <option value={value} selected={this.state.activeStatus == value}>
                    {filterKey[value]}
                  </option>
                ))}
              </select>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  <CSVLink data={this.state.csvData} filename={'transactions.csv'}>
                    {this.state.loadingCsv ? 'Loading csv...' : 'Export'}
                  </CSVLink>
                </button>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-start g-3 mb-4">
            <div>
              <DatePicker
                placeholderText="From Date"
                selected={this.state.date_from}
                onChange={(dateStr) => {
                  this.setState({ date_from: dateStr });
                  if (this.state.date_to) {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          date_from: moment(dateStr).format('YYYY-MM-DD'),
                          date_to: moment(this.state.date_to).format('YYYY-MM-DD'),
                        },
                      },
                      () => {
                        this.paginationHandler({ selected: 0 });
                      },
                    );
                  }
                }}
              />
            </div>
            <div class="ms-3">
              <DatePicker
                placeholderText="To Date"
                selected={this.state.date_to}
                onChange={(dateStr) => {
                  this.setState({ date_to: dateStr });
                  if (this.state.date_from) {
                    this.setState(
                      {
                        filter: {
                          ...this.state.filter,
                          date_to: moment(dateStr).format('YYYY-MM-DD'),
                          date_from: moment(this.state.date_from).format('YYYY-MM-DD'),
                        },
                      },
                      () => {
                        this.paginationHandler({ selected: 0 });
                      },
                    );
                  }
                }}
              />
            </div>
          </div>
          <div class="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div class="table-responsive scrollbar ms-n1 ps-1">
              <table class="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="date"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      REQUEST DATE
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="_id"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      PAYOUT ID
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="payout"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      PAYOUT
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="status"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      STATUS
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="seller"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      SELLER
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="reason"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      Reason
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="amount"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      AMOUNT
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="fee"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      FEE
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="total"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      TOTAL
                    </th>
                    <th
                      class="sort align-middle text-start"
                      scope="col"
                      data-sort="reason"
                      style={{ width: '15%', minWidth: '150px' }}
                    >
                      Transaction ID
                    </th>
                  </tr>
                </thead>
                <tbody class="list" id="members-table-body">
                  {this.state.transactionList.length > 0 ? (
                    this.state.transactionList.map((method, index) => {
                      const { seller_info, payout_method, amount } = method;
                      let infoHTML = '';
                      if (seller_info && payout_method) {
                        const payout_info = seller_info?.payout_info ? JSON.parse(seller_info?.payout_info) : {};
                        const exclude = ['selected', 'payout', 'fee', 'is_default'];

                        if (Array.isArray(payout_info)) {
                          infoHTML = '';
                          infoHTML += `<strong class="text-capitalize">Payout Method: </strong>${payout_method.name} <br/>`;
                          const find = payout_info.filter((m) => m.payout_id === payout_method?._id);
                          if (find.length > 0) {
                            Object.keys(find[0]).forEach((el) => {
                              const findKey = exclude.filter((m) => el.indexOf(m) >= 0);
                              if (findKey.length === 0)
                                infoHTML += `<strong class="text-capitalize">${el.replaceAll('_', ' ')}: </strong>${
                                  find[0][el]
                                } <br/>`;
                            });
                          }
                        } else {
                          Object.keys(payout_info).forEach((el) => {
                            const findKey = exclude.filter((m) => el.indexOf(m) >= 0);
                            if (findKey.length === 0)
                              infoHTML += `<strong class="text-capitalize">${el.replaceAll('_', ' ')}: </strong> ${
                                payout_info[el]
                              } <br/>`;
                          });
                        }
                      }

                      const subtotal = payout_method
                        ? (amount * payout_method.fee || 0) / 100 + payout_method.additional_fee || 0
                        : 0;
                      const total = amount - subtotal;

                      return (
                        <tr key={index} class="hover-actions-trigger btn-reveal-trigger position-static">
                          <td class="date align-middle white-space-nowrap">{formatDate(method.created_time * 1000)}</td>
                          <td class="_id align-middle white-space-nowrap">{method._id}</td>
                          <td class="payout align-middle white-space-nowrap">
                            <div dangerouslySetInnerHTML={{ __html: infoHTML }} />
                          </td>
                          <td class="status align-middle white-space-nowrap">
                            <span
                              class={`badge badge-phoenix fs-10 badge-phoenix-${
                                statusesColor[method.status]
                              } d-inline-flex align-items-center`}
                            >
                              {statuses[method.status]}
                            </span>
                          </td>
                          <td class="align-middle white-space-nowrap">
                            <h6 class="seller_name mb-0 fw-semibold">{method.seller_info.seller_name}</h6>
                            <small>{getUserId(method.seller_id)}</small>
                          </td>
                          <td class="align-middle white-space-nowrap">
                            {method.status == 1 ? (
                              <textarea
                                value={this.state.reasonList[index]}
                                onChange={(e) => {
                                  const newArr = [...this.state.reasonList];
                                  newArr[index] = e.target.value;
                                  this.setState({
                                    ...this.state,
                                    reasonList: newArr,
                                  });
                                }}
                              />
                            ) : (
                              method.reason
                            )}
                          </td>
                          <td class="amount align-middle white-space-nowrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(method.amount, true, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td class="fee align-middle white-space-nowrap">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(subtotal, true, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td class="amount align-middle white-space-nowrap">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertCurrency(total, true, this.props.settings?.data?.sgd),
                              }}
                            />
                          </td>
                          <td class="align-middle white-space-nowrap">
                            {method.status == 1 ? (
                              <textarea
                                value={this.state.transactionIdList[index]}
                                onChange={(e) => {
                                  const newArr = [...this.state.transactionIdList];
                                  newArr[index] = e.target.value;
                                  this.setState({
                                    ...this.state,
                                    transactionIdList: newArr,
                                  });
                                }}
                              />
                            ) : (
                              method.transactionId
                            )}
                          </td>
                          <td class="action align-middle white-space-nowrap text-body-tertiary text-end">
                            {method.status == 1 && (
                              <>
                                <a
                                  class="px-2 text-success"
                                  href="#"
                                  onClick={() => this.handleApprove(method._id, index, 2)}
                                >
                                  Approve
                                </a>
                                <a
                                  class="px-2 text-success"
                                  href="#"
                                  onClick={() => this.handleApprove(method._id, index, 3)}
                                >
                                  Reject
                                </a>
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div className="d-flex justify-content-center mt-3">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={this.state.total_page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Payouts.propTypes = {
  auth: PropTypes.object.isRequired,
  payouts: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getPayoutsLists: PropTypes.func.isRequired,
  updatePayout: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  payouts: state.transactions.payouts,
  settings: state.paymentMethods?.settings,
});

export default connect(mapStatetoProps, {
  getPayoutsLists,
  updatePayout,
})(withRouter(Payouts));
