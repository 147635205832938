import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import { getExpenseLists, deleteExpense } from '../../../actions/expense';

import { convertCurrency, formatDate } from '../../../utils/helpers';
import { withRouter } from '../../../withRouter';
import queryString from 'query-string';

class Expenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        page: 1,
        page_size: 10,
      },
      expenseList: [],
      total_items: 0,
      expense: {},
      date_from: '',
      date_to: '',
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.paginationHandler = this.paginationHandler.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }
  handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure delete this?');
    if (confirm) {
      await this.props.deleteExpense(id);
      this.paginationHandler({ selected: this.state.filter.page - 1 });
    }
    return false;
  };
  paginationHandler = (page) => {
    if (this.props.auth.isAuthenticated) {
      this.props.getExpenseLists({
        ...this.state.filter,
        page: page.selected + 1,
      });
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          page: page.selected + 1,
        },
        isLoaded: true,
      });
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      this.setState({
        ...this.state,
        isLoaded: true,
      });
    }
  };

  handlePagination(page) {
    if (!this.state.isLoaded) {
      return;
    }
    const pathname = window.location.pathname;
    const arg = {
      page: page.selected + 1,
    };
    if (this.state.filter.date_to) {
      arg.date_to = this.state.filter.date_to;
    }
    if (this.state.filter.date_from) {
      arg.date_from = this.state.filter.date_from;
    }
    const params = new URLSearchParams(arg);
    const url = `${pathname}?${params}`;
    window.location.href = url;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.expenses !== this.props.expenses) {
      this.setState({
        ...this.state,
        expenseList: nextProps.expenses.expenses.data.expenses,
        total_items: nextProps.expenses.expenses.data.total_items,
      });
    }
  }

  componentDidMount() {
    const params = this.props.router?.location?.search;
    const query = queryString.parse(params);
    let page = parseInt(query.page) || 1;
    page = page > 0 ? page - 1 : 0;
    const date_to = query.date_to;
    const date_from = query.date_from;
    if (date_to || date_from) {
      this.setState(
        {
          ...this.state,
          filter: {
            ...this.state.filter,
            date_to,
            date_from,
          },
        },
        () => {
          this.paginationHandler({ selected: page });
        },
      );
    } else {
      this.paginationHandler({ selected: page });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Accounting</a>
            </li>
            <li className="breadcrumb-item active">Expenses</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Expenses</h2>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto ">
              <div className="d-flex align-items-center justify-content-start">
                <DatePicker
                  placeholderText="From Date"
                  selected={this.state.date_from}
                  onChange={(dateStr) => {
                    this.setState({ date_from: dateStr });
                    if (this.state.date_to) {
                      this.setState(
                        {
                          filter: {
                            ...this.state.filter,
                            date_from: moment(dateStr).format('YYYY-MM-DD'),
                            date_to: moment(this.state.date_to).format('YYYY-MM-DD'),
                          },
                        },
                        () => {
                          this.paginationHandler({ selected: 0 });
                        },
                      );
                    }
                  }}
                />
                <div class="ms-3">
                  <DatePicker
                    placeholderText="To Date"
                    selected={this.state.date_to}
                    onChange={(dateStr) => {
                      this.setState({ date_to: dateStr });
                      if (this.state.date_from) {
                        this.setState(
                          {
                            filter: {
                              ...this.state.filter,
                              date_to: moment(dateStr).format('YYYY-MM-DD'),
                              date_from: moment(this.state.date_from).format('YYYY-MM-DD'),
                            },
                          },
                          () => {
                            this.paginationHandler({ selected: 0 });
                          },
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <button className="btn btn-link text-body me-4 px-0">
                  <span className="fa-solid fa-file-export fs-9 me-2"></span>
                  Export
                </button>
                <a href="expense/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add expense
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th className="white-space-nowrap fs-9 align-middle ps-0">
                      <div className="form-check mb-0 fs-8">
                        <input
                          className="form-check-input"
                          id="checkbox-bulk-members-select"
                          type="checkbox"
                          data-bulk-select='{"body":"members-table-body"}'
                        />
                      </div>
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="requested_date"
                      style={{ width: '20%', minWidth: '100px' }}
                    >
                      REQUESTED DATE
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="name"
                      style={{ width: '25%', minWidth: '100px' }}
                    >
                      NAME
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="category"
                      style={{ width: '25%', minWidth: '100px' }}
                    >
                      CATEGORY
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="comment"
                      style={{ width: '30%', minWidth: '200px' }}
                    >
                      AMOUNT
                    </th>
                    <th className="sort align-middle text-start" scope="col"></th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {(this.state.expenseList || []).length > 0 ? (
                    (this.state.expenseList || []).map((expense) => (
                      <tr className="hover-actions-trigger btn-reveal-trigger position-static">
                        <td className="fs-9 align-middle ps-0 py-3">
                          <div className="form-check mb-0 fs-8">
                            <input className="form-check-input" type="checkbox" data-bulk-select-row="[]" />
                          </div>
                        </td>
                        <td className="customer align-middle white-space-nowrap">
                          {formatDate(expense?.requested_date * 1000)}
                        </td>
                        <td className="seller align-middle white-space-nowrap">
                          <span className="fw-bold text-body-emphasis">{expense?.name}</span>
                        </td>

                        <td className="joined align-middle white-space-nowrap text-body-tertiary">
                          {expense?.category?.name || expense?.category_id}
                        </td>
                        <td className="mobile_number align-middle white-space-nowrap">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: convertCurrency(expense?.amount, true, this.props.settings?.data?.sgd),
                            }}
                          />
                        </td>

                        <td className="joined align-middle white-space-nowrap text-body-tertiary text-end">
                          <div className="btn-reveal-trigger position-static">
                            <button
                              className="btn btn-sm dropdown-toggle dropdown-caret-none transition-none btn-reveal fs-10"
                              type="button"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <span className="fas fa-ellipsis-h fs-10"></span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end py-2">
                              <a className="dropdown-item" href={`expense/edit/${expense._id}`}>
                                Edit
                              </a>
                              <a
                                className="dropdown-item text-danger"
                                href="#!"
                                onClick={() => this.handleDelete(expense._id)}
                              >
                                Remove
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8}>No Data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="row align-items-center justify-content-between py-2 pe-0 fs-9">
              <div className="d-flex justify-content-center mt-3">
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  activeClassName={'active'}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  pageLinkClassName={'page'}
                  breakLinkClassName={'page'}
                  previousClassName={'page-link'}
                  nextClassName={'page-link'}
                  initialPage={this.state.filter.page - 1}
                  forcePage={this.state.filter.page - 1}
                  pageCount={Math.ceil(this.state.total_items / 10)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Expenses.propTypes = {
  auth: PropTypes.object.isRequired,
  expenses: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getExpenseLists: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  expenses: state.expenses,
  errors: state.errors,
  settings: state.paymentMethods?.settings,
  loaded: state.loadingBar.sectionBar == 1 ? true : false,
});

export default connect(mapStateToProps, {
  getExpenseLists,
  deleteExpense,
})(withRouter(Expenses));
