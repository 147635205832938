import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getMarketingBannerLists, deleteMarketingBanner } from '../../../actions/marketing';

class Marketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      marketings: [],
    };
  }

  handleDelete = async (id) => {
    const confirm = window.confirm('Are you sure delete this banner?');
    if (confirm) {
      await this.props.deleteMarketingBanner(id);
      this.props.getMarketingBannerLists();
    }
    return false;
  };

  componentDidMount() {
    this.props.getMarketingBannerLists();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.marketings !== this.props.marketings) {
      this.setState({
        ...this.state,
        marketings: nextProps.marketings?.marketings?.data,
      });
    }
  }

  render() {
    return (
      <>
        <nav className="mb-2" aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <a href="#!">Settings</a>
            </li>
            <li className="breadcrumb-item active">Marketing</li>
          </ol>
        </nav>
        <h2 className="text-bold text-body-emphasis mb-5">Marketing</h2>
        <div id="members" className="table-list">
          <div className="row align-items-center justify-content-between g-3 mb-4">
            <div className="col col-auto d-flex flex-row">
              <div className="search-box me-1"></div>
            </div>
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <a href="/admin/marketing/banners/add" className="btn btn-primary">
                  <span className="fas fa-plus me-2"></span>Add new
                </a>
              </div>
            </div>
          </div>
          <div className="mx-n4 mx-lg-n6 px-4 px-lg-6 mb-9 bg-body-emphasis border-y mt-2 position-relative top-1">
            <div className="table-responsive scrollbar ms-n1 ps-1">
              <table className="table table-sm fs-9 mb-0">
                <thead>
                  <tr>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="date"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      NAME
                    </th>
                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="date"
                      style={{ width: '15%', maxWidth: '150px' }}
                    >
                      TAGS
                    </th>

                    <th
                      className="sort align-middle text-start"
                      scope="col"
                      data-sort="total"
                      style={{ width: '5%', minWidth: '100px' }}
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                <tbody className="list" id="members-table-body">
                  {this.state.marketings?.length > 0 ? (
                    this.state.marketings.map((method, index) => {
                      return (
                        <tr key={index} className="hover-actions-trigger btn-reveal-trigger position-static">
                          <td className="align-middle white-space-nowrap">
                            <h6 className="seller_name mb-0 fw-semibold">{method.name}</h6>
                          </td>
                          <td className="align-middle white-space-nowrap">
                            <h6
                              className="seller_name mb-0 fw-semibold"
                              dangerouslySetInnerHTML={{ __html: method.tags.join(', ') }}
                            ></h6>
                          </td>
                          <td className="action align-middle white-space-nowrap text-body-tertiary text-end">
                            <a className="px-2 text-success" href={`/admin/marketing/banners/edit/${method._id}`}>
                              Edit
                            </a>
                            <a className="px-2 text-danger" href="#" onClick={() => this.handleDelete(method._id)}>
                              Delete
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5}>No data</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Marketing.propTypes = {
  auth: PropTypes.object.isRequired,
  marketings: PropTypes.object.isRequired,
  getMarketingBannerLists: PropTypes.func.isRequired,
  deleteMarketingBanner: PropTypes.func.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
  marketings: state.marketings,
});

export default connect(mapStatetoProps, {
  getMarketingBannerLists,
  deleteMarketingBanner,
})(Marketing);
