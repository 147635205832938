import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

const navigations = [
  {
    parent: 'Settings',
    icon: '<i className="fa-solid fa-screwdriver-wrench"></i>',
    children: {
      'Users (admin)': '/admin/settings/members',
      'Users (verified)': '/admin/settings/users',
      'Users (unverified)': '/admin/settings/users/inactive',
      'Users (blocked)': '/admin/settings/users/block',
      'Seller ranks': '/admin/settings/seller-ranks',
      'Buyer ranks': '/admin/settings/buyer-ranks',
      'Payment methods': '/admin/settings/payment-methods',
      'Payout methods': '/admin/settings/payout-methods',
      Settings: '/admin/settings/payment',
    },
  },
  {
    parent: 'Accounting',
    icon: '<i className="fa-solid fa-chart-pie"></i>',
    children: {
      'Financial report': '#',
      'Financial analytics': '#',
      'Expense Category': '/admin/accounting/expense-categories',
      Expense: '/admin/accounting/expense',
    },
  },
  {
    parent: 'Games',
    icon: '<i className="fa-solid fa-gamepad"></i>',
    children: {
      'Game Type (loại game)': '/admin/games/types',
      'Product Type (loại sp)': '/admin/games/product-types',
      Games: '/admin/games',
      Products: '/admin/games/products',
    },
  },
  {
    parent: 'Marketplace',
    icon: '<i className="fa-solid fa-shop"></i>',
    children: {
      'Buyers / Sellers': '/admin/marketplace/users',
      'Buyers / Sellers (inactive)': '/admin/marketplace/users/inactive',
      'Payout report': '/admin/marketplace/payout-report',
      Transactions: '/admin/marketplace/transactions',
      'Refund Request': '/admin/marketplace/refund',
      Reviews: '/admin/marketplace/reviews',
      Subscribers: '/admin/marketplace/subscribers',
    },
  },
  {
    parent: 'Affliate',
    icon: '<i className="fa-solid fa-headset"></i>',
    children: {
      Transactions: '/admin/affiliate/transactions',
      Payouts: '/admin/affiliate/payouts',
    },
  },
  {
    parent: 'Marketing',
    icon: '<i className="fa-solid fa-headset"></i>',
    children: {
      Banners: '/admin/marketing/banners',
    },
  },
  {
    parent: 'Customer Support',
    icon: '<i className="fa-solid fa-headset"></i>',
    children: {
      'Live Chat': '/admin/support/chat',
      Dispute: '/admin/support/disputes',
      'Support ticket': '/admin/support/tickets',
      KYC: '/admin/support/kyc',
    },
  },
];

class Navbar extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <nav className="navbar navbar-vertical navbar-expand-lg">
        <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
          <div className="navbar-vertical-content">
            <ul className="navbar-nav flex-column" id="navbarVerticalNav">
              <li className="nav-item">
                <p className="navbar-vertical-label">Dashboard</p>
                <hr className="navbar-vertical-line" />
                {navigations.map((navigation, index) => (
                  <div key={index} className="nav-item-wrapper">
                    <a className="nav-link dropdown-indicator">
                      <div className="d-flex align-items-center">
                        <span className="nav-link-icon">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: navigation['icon'],
                            }}
                          />
                        </span>
                        <span className="nav-link-text">{navigation['parent']}</span>
                      </div>
                    </a>
                    <div className="parent-wrapper label-1">
                      <ul className="nav parent" data-bs-parent="#navbarVerticalCollapse" id="nv-CRM">
                        <li className="collapsed-nav-item-title d-none">User Manager</li>
                        {Object.keys(navigation['children']).map((el, index) => (
                          <li className="nav-item" key={index}>
                            <Link className="nav-link" to={navigation['children'][el]}>
                              <div className="d-flex align-items-center">
                                <span className="nav-link-text">{el}</span>{' '}
                                {navigation['children'][el] == '#' ? '*' : ''}
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStatetoProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStatetoProps, {})(Navbar);
